<template>
  <div>
    <Header :currentIndex="currentIndex" :navStyle="navStyle"></Header>
    <div class="news_list_banner">
      <div class="news_list_banner_content flex-wrap-st align-ctc">
        <div>
          <h2>教育 - 精选报告</h2>
          <p>根据最新教育行业权威报告统计文章</p>
          <p>汇聚精华+分享智慧+共赢未来</p>
        </div>
      </div>
    </div>

    <div class="news_list_content_wrap">
      <div class="news_list_content">
        <div v-for='(item) in articleData' :key='item.id' class="flex-wrap-st news_list_item" @click="goNewsDetail(item.id, item.price)">
            <div class="news_list_item_img">
                <img :src="item.cover" alt="">
                <div class="recommend_article_thumb_tag">{{item.price}}元阅读</div>
            </div>
            <div class="txt_info">
                <h4 class="title">{{item.title}}</h4>
                <div class="subtitle">{{item.subtitle}}</div>
            </div>
        </div>
      </div>
      <div class="flex-wrap-st justify-ctc pag_footer">
        <el-pagination
        background
        layout="prev, pager, next"
       
        :total="totalNum"
        @prev-click="prevClick"
        @next-click="nextClick"
        @size-change="sizeChange"
        @current-change="currentChange"
        >
        </el-pagination>
     </div>
    </div>
    <Footer></Footer>
    <div class="payPop">
            <el-dialog title="微信支付" center :visible.sync="payDialogVisible" width='311px' :show-close='false'>
            <!-- <div slot='title'  center>微信支付</div> -->
            <div class="payImg_er">
                <label>¥{{price}}</label>
                <img :src="payImg" alt="">
            </div>
                <p class="company_name">北京玛峰教育科技有限公司</p>


                <div class="content_footer">
                    <div class="content_footer_title flex-wrap-st justify-ctc align-ctc">
                        <div><img src="@/assets/wechartpayicon.png" alt=""></div>
                        <label>微信支付</label>
                    </div>
                    <div class="payStatusBtn" @click="checkStatus">我已完成支付</div>
                </div>
            </el-dialog>
        </div>

        <el-dialog
        title="提示"
        center
        :visible.sync="noLoginStatus"
        width="350px"
        :before-close="handleNoLoginClose">
        <span>登录后才能查看文章哦</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="noLoginStatus = false">取 消</el-button>
            <el-button type="primary" @click="goLogin">去登录</el-button>
        </span>
        </el-dialog>
  </div>
</template>

<script>
import { articleDetail } from "../api/newsDetail";
import Footer from "../components/footer";
import { articleList, artiteminfo } from "../api/index";
import Header from "../components/header";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      currentIndex: 2,
      navStyle: "blacks",
      currentId:'',
      payDialogVisible:false,
      payImg:'',
      price:'',
      totalNum: 0,
      articleData: [],
      noLoginStatus: false,
      listQuery: {
          page:1,
          limit: 10
      }
    };
  },
  methods: {
    articleListFc(t1, t2) {
      articleList(t1, t2).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.articleData = data.data.data;
            this.totalNum = data.data.total
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    checkStatus() {
          articleDetail(this.currentId).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
               this.$router.push({
              path: '/newsDetail',
                  query: {
                      id: this.currentId
                  }
              })
            } else {
              this.$message.error(data.message);
            }
          }
        });
      },
    artiteminfoFc(t1, t2) {
          artiteminfo(t1).then(res => {
              
            if (res.data.code == 200) {
                let data = res.data.data
                
                if (data.error == 0) {
                    // console.log(data)
                
                    this.payDialogVisible = true
                    this.payImg = data.data
                    this.price = t2
                } else {
                   this.goNewsDetail(t1)
                }
            } else {
                this.noLoginStatus = true
               
            }
          })
      },
      goLogin() {
          this.$router.push('/login')
      },
      handleNoLoginClose() {
          this.noLoginStatus = false
      },
    goNewsDetail(e, x) {
        this.currentId = e
        articleDetail(e).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data
            if (data.error == 0) {
              this.$router.push({
              path: '/newsDetail',
                  query: {
                      id: e
                  }
              })
            } else {
               this.artiteminfoFc(e, x)
              
            }
          } else {
              this.noLoginStatus = true
          }
        })
          
      },
     prevClick() {
      if (this.listQuery.page !== 0) {
        this.listQuery.page--;
        this.articleListFc(this.listQuery.page, this.listQuery.limit);
      }
    },
    nextClick() {
      this.listQuery.page++;
      this.articleListFc(this.listQuery.page, this.listQuery.limit);
    },
    sizeChange(e) {
      this.articleListFc(e, this.listQuery.limit);
    },
    currentChange(e) {
      this.articleListFc(e, this.listQuery.limit);
    },
  },
  created() {
    let listQuery = this.listQuery
    this.articleListFc(listQuery.page, listQuery.limit);
  },
};
</script>

<style scoped>
.news_list_banner {
  width: 100%;
  height: 240px;
  background: url("../assets/newsListBanner.png") center no-repeat;
  background-size: cover;
}
.news_list_banner_content {
  width: 1200px;
  height: 240px;

  margin: auto;
}
.news_list_banner_content h2 {
  font-size: 38px;
  color: #333333;
  font-weight: normal;
  margin-bottom: 9px;
}
.news_list_banner_content p {
  color: #333333;
  font-size: 14px;
  line-height: 150%;
}
.news_list_content{
    width: 1200px;
    margin: 100px auto;
}
.recommend_article_thumb_tag {
  position: absolute;
  width: 65px;
  height: 20px;
  background: #fdd000;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  text-align: center;
}
.news_list_item{
    margin-bottom: 40px;
    cursor: pointer;
}
.news_list_item_img{
    width: 300px;
    flex-shrink: 0;
    margin-right: 35px;
    position: relative;
    
}
.news_list_item_img img{
    width: 100%;
    display: block;
    
}
.title{
   font-size: 20px;
   color: #333333; 
   margin-bottom: 26px;
      overflow: hidden;
text-overflow: ellipsis;
display: box;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.subtitle{
    font-size: 14px;
    color: #666666;
    width: 100%;
    overflow: hidden;
text-overflow: ellipsis;
display: box;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
.txt_info{
    width: 100%;
}
.pag_footer{
    margin-bottom: 80px;
}
.payPop >>>.el-dialog__header{
    background: #21AB39;
    padding: 27px 0;
}
.payPop >>>.el-dialog__body{
    background: #21AB39;
    padding: 0;
}
.payPop >>>.el-dialog__title{
    color: #ffffff;
}
.payImg_er{
    width: 200px;
    height: auto;
    display: block;
    margin: auto;
    background: #ffffff;
    color: #ffffff;
}
.payImg_er label{
    color: #333333;
    font-size: 14px;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px 0;
    
}
.payImg_er img{
    width: 100%;
    display: block;
}
.company_name{
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 19px;
}
.content_footer_title{
    margin-bottom: 19px;
}
.content_footer_title img{
    width: 48px;
    margin-right: 14px;

}
.content_footer_title label{
    font-size: 32px;
    color: #333333;
    font-weight: normal;
}
.payStatusBtn{
    width: 255px;
    height: 45px;
    border-radius: 4px;
    background: #21AB39;
    color: #ffffff;
    text-align: center;
    line-height: 45px;
    margin: auto;
    cursor: pointer;
}
.content_footer{
    background: #ffffff;
    padding: 18px 0px;
}
</style>
